//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ctaComponent from '~/components/cmscontent/cta/index.vue'
export default {
  components: { ctaComponent },
  props: {
    info: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isPc: {
      type: Boolean,
      default: true,
    },
    cardType: {
      type: String,
      default: '',
    },
    resouceType: {
      type: [String, Number],
      default: '',
    },
    props: {
      type: Object,
      default() {
        return {
          title: 'content_title',
          createTime: 'content_create_time',
          creator: 'content_author',
          imgUrl: 'content_cover',
          viewcount: 'viewcount',
          imgUrl_m: 'content_cover_m',
        }
      },
    },
    // 首页设置的相关信息
    homeSettingInfo: {
      type: Object,
      default: () => {},
    },
    isStatistics: {
      type: [String, Number],
      default: '',
    },
    pmContent: {
      type: String,
      default: '',
    },
    host: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      link: '',
    }
  },
  computed: {
    imgUrl() {
      return this.prefixImageUrl(process.env.NODE_ENV)
    },
    iframeLink() {
      let pType = ''
      let pId = ''
      if (this.resouceType == 1) {
        pType = this.info.type == 1 ? 'contenthp' : ''
        pId = this.info.type == 1 ? -1 : ''
      } else {
        pType = this.info.type == 1 ? 'blog' : 'ebook'
        pId = this.info.id
      }
      let pm = this.isPc ? '' : 'm'
      // console.log('123456', this.getCaption(this.info.cta_url))

      if (this.info.cta_url.includes('https')) {
        return `${this.info.cta_url}&pType=${pType}&pId=${pId}&release=1&isStatistics=${this.isStatistics}&pm=${pm}`
      } else {
        return `https://${this.info.cta_url}&pType=${pType}&pId=${pId}&release=1&isStatistics=${this.isStatistics}&pm=${pm}`
      }
    },
  },
  watch: {
    info: {
      handler(value) {
        if (value && Object.keys(value).length && !value.is_define) {
          if (!value.content_url) return
          this.jump(value)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  methods: {
    getCaption(obj) {
      var index = obj.lastIndexOf('/') //获取-后边的字符串
      obj = obj.substring(index + 1, obj.length)
      //  console.log(obj);
      return obj
    },

    jump(info) {
      let wechat = this.$route.query.wechat ? this.$route.query.wechat : ''
      let pType = ''
      let pId = ''
      if (this.resouceType == 1) {
        pType = info.type == 1 ? 'contenthp' : ''
        pId = info.type == 1 ? -1 : ''
      } else {
        pType = info.type == 1 ? 'blog' : 'ebook'
        pId = info.content_id
      }
      let pm = this.pmContent == 'm' ? 'm' : ''
      let wechatFlag = wechat ? `&wechat=${wechat}` : ''
      // window.location.href = `${this.info.content_url}&gType=contenthp&gId=-1&pType=${pType}&pId=${pId}&isStatistics=${this.isStatistics}&pm=${pm}`
      let link = ''
      if (info.content_url.includes('https')) {
        link = `${info.content_url}?gType=contenthp&gId=-1&pType=${pType}&pId=${pId}&isStatistics=${this.isStatistics}&pm=${pm}${wechatFlag}`
      } else {
        link = `https://${info.content_url}?gType=contenthp&gId=-1&pType=${pType}&pId=${pId}&isStatistics=${this.isStatistics}&pm=${pm}${wechatFlag}`
      }
      this.link = link
    },
    handleUpFormCta(e) {
      console.log(e)
    },
  },
}
