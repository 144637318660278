var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.isPc ||
      _vm.cardType === 'zoom' ||
      (_vm.info.type != 2 && _vm.info.type != undefined)
    )?_c('div',[_c('div',{staticClass:"content-card-outside"},[(_vm.info.type === 2 || _vm.info.type === undefined)?_c('a',{staticClass:"content-card",class:[
          'content-card',
          {
            'normal-card': _vm.cardType !== 'zoom' && _vm.isPc,
            'm-normal-card': _vm.cardType !== 'zoom' && !_vm.isPc,
            'zoom-card': _vm.cardType === 'zoom' && _vm.isPc,
            'm-zoom-card': _vm.cardType === 'zoom' && !_vm.isPc,
          } ],attrs:{"href":_vm.link,"target":"_blank"}},[_c('img',{key:_vm.info[_vm.props.imgUrl],staticClass:"wp100",attrs:{"src":_vm.info[_vm.props.imgUrl] ? _vm.imgUrl + _vm.info[_vm.props.imgUrl] : '',"alt":_vm.info[_vm.props.title],"title":_vm.info[_vm.props.title]}}),_vm._v(" "),_c('h4',{staticClass:"mt20 text-overflow-area pointer",attrs:{"alt":_vm.info[_vm.props.title]}},[_c('span',[_vm._v(_vm._s(_vm.info[_vm.props.title]))])]),_vm._v(" "),(_vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(1))?_c('span',{staticClass:"wp100 border-box font-size-14 font_color_desc",class:[
            _vm.isPc ? 'mt48' : 'mt60',

            {
              'opacity-hidden': !(
                _vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(1)
              ),
            } ]},[_vm._v(_vm._s(_vm.info[_vm.props.creator]))]):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mt8 wp100 flex jcs border-box alc font-size-14"},[(_vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(2))?_c('span',{staticClass:"font_color_desc"},[_vm._v(_vm._s(_vm.info[_vm.props.createTime]))]):_vm._e(),_vm._v(" "),(_vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(3))?_c('span',{staticClass:"font_color_desc flex alc",class:{
              'opacity-hidden': !(
                _vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(3)
              ),
            }},[_c('i',{staticClass:"font_color_desc font_family icon-zhanshi mr4"}),_c('span',{staticClass:"font_color_desc"},[_vm._v(_vm._s(_vm.info[_vm.props.viewcount]))])]):_vm._e()])]):_c('div',{staticClass:"content-card",class:{
        'normal-card': _vm.cardType !== 'zoom' && _vm.isPc,
        'm-normal-card': _vm.cardType !== 'zoom' && !_vm.isPc,
        'zoom-card': _vm.cardType === 'zoom' && _vm.isPc,
        'm-zoom-card': _vm.cardType === 'zoom' && !_vm.isPc,
      },},[_c('div',{staticClass:"form-cta"},[_c('ctaComponent',{attrs:{"cta-link":_vm.iframeLink}})],1)])])]):_c('div',[_c('a',{staticClass:"m-content-card-heng",attrs:{"href":_vm.link,"target":"_blank"}},[_c('div',{staticClass:"heng-left"},[_c('h4',{staticClass:"text-overflow-area2 pointer",attrs:{"alt":_vm.info[_vm.props.title]}},[_c('span',[_vm._v(_vm._s(_vm.info[_vm.props.title]))])]),_vm._v(" "),_c('div',{staticClass:"nameDiv"},[(_vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(1))?_c('p',{staticClass:"authorName"},[_vm._v("\n            "+_vm._s(_vm.info[_vm.props.creator])+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"hengBottom"},[(
                _vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(2)
              )?_c('p',[_vm._v("\n              "+_vm._s(_vm.info[_vm.props.createTime])+"\n            ")]):_vm._e(),_vm._v(" "),_c('p',[(
                  _vm.homeSettingInfo && _vm.homeSettingInfo.display_type.includes(3)
                )?_c('span',{staticClass:"font_color_desc flex alc",class:{
                  'opacity-hidden': !(
                    _vm.homeSettingInfo &&
                    _vm.homeSettingInfo.display_type.includes(3)
                  ),
                }},[_c('i',{staticClass:"font_color_desc_new font_family icon-zhanshi mr4"}),_c('span',[_vm._v(_vm._s(_vm.info[_vm.props.viewcount]))])]):_vm._e()])])])]),_vm._v(" "),_c('div',{staticClass:"rightImg"},[_c('img',{key:_vm.info[_vm.props.imgUrl],staticClass:"wp100",class:{ coverImg: !_vm.info[_vm.props.imgUrl_m] },attrs:{"src":_vm.info[_vm.props.imgUrl_m]
              ? _vm.imgUrl + _vm.info[_vm.props.imgUrl_m]
              : _vm.imgUrl + _vm.info[_vm.props.imgUrl],"alt":_vm.info[_vm.props.title],"title":_vm.info[_vm.props.title]}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }